.buttonContainer {
  border: none;

  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 14px;
}
  .iconUserDiv i {
      padding: 5px;
      color: var(--white);
}
.categoryBtnDiv {
  display: flex;
  justify-content: flex-end;
  margin: 0px 17px;
}
.userloggedinBtn {
  background-color: transparent;
  padding: 10px;
  height: 34px;
  width: 108px;
  color: var(--white) ;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 30px;
  cursor: pointer;
}
 .userloggedinBtn i {
    padding: 5px !important;
    color: var(--white) !important;
}

@media (max-width: 768px) {
  .categoryBtnDiv {
    margin: 0px 2px !important;
  }
  .categoryBtnDivAr {
    margin: 0px 16px !important;
  }
}

