.adverPar{
    color: var(--white);
    text-align: center;
    padding-top: 2px;
    margin-top: 8px;
    height: fit-content !important;
    padding-bottom: 3px;
}
.containerAdver p{
    height: fit-content !important;
}
.glyphsContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
}
.glyphsContainer p {
    margin-right: 20px;
}
.adverPar:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.adverPar img{
    margin-bottom: 10px;
}
.adverPar a{
    text-decoration: underline;
    color: #2dcafb !important;
    font-weight: 600;
}
.containerAdver{
    width: 100%;
    background-color: #141b2d;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content !important;
    z-index: 2000;
}
.logoGlyphs{
    width: 50px;
    height: 40px;
}

@media(max-width:450px){
    .adverPar:first-child{
        display: flex;
        font-size: 12px;
        align-items: center;
        
    }
  
    .adverPar{
        font-size: 12px;
    }
    .adverPar p{
        font-size: 12px;

    }
}